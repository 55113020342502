import "./App.css";
import { Switch, Route } from "react-router";
import { BrowserRouter } from "react-router-dom";

import ForgotPasswordPage from "./components/pages/ForgotPasswordPage";
import ResetPasswordPage from "./components/pages/ResetPasswordPage";
import FeedPage from "./components/pages/FeedPage";
import DefaultPage from "./components/pages/DefaultPage";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./components/common.css";
import packageJson from "../package.json";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/forgotpassword" exact component={ForgotPasswordPage} />
          <Route path="/resetpassword" exact component={ResetPasswordPage} />
          <Route path="/feed" exact component={FeedPage} />
          <Route path="/" component={DefaultPage} />
        </Switch>
      </BrowserRouter>
      {/* <div className="version">v{packageJson.version}</div> */}
    </div>
  );
}

export default App;
