import APIPaths from "../configs/APIPaths";
import APIResult from "../configs/APIResult";
import APIHeaders from "../configs/APIHeaders";
import { createBrowserHistory } from "history";
import { debugPrint } from "./Utilities";

async function callAPI(body, target, defaultReturn) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": APIHeaders.contentType,
      apikey: APIHeaders.apiKey,
    },
    body: JSON.stringify(body),
  };

  debugPrint(target);
  debugPrint(body);

  var proceed = true;
  Object.values(body).forEach(param => {
    if (param === null || param === undefined) {
      proceed = false;
    }
  });

  if (proceed) {
    await new Promise(resolve => setTimeout(resolve, 500));
    return await fetch(target, requestOptions)
      .then(response => response.json())
      .then(responseData => {
        // debugPrint(responseData);

        try {
          if (responseData.status === APIResult.INVALID_SESSION) {
            createBrowserHistory().push("/dashboardlogin?to=1");
            window.location.reload();
          }
        } catch (e) {}
        return responseData;
      })
      .catch(error => {
        console.warn(error);
        return defaultReturn;
      });
  } else {
    return defaultReturn;
  }
}

export async function passwordRequestReset(user_email) {
  var body = {
    user_email,
  };
  var target = APIPaths.apiEndPoint.concat(APIPaths.resetPasswordRequest);

  return callAPI(body, target, {
    status: APIResult.TIME_OUT,
    status_message: "-",
    t: [],
  });
}

export async function resetPassword(datetime, token, password) {
  var body = {
    datetime: datetime,
    token: token,
    new_pw: password,
  };
  var target = APIPaths.apiEndPoint.concat(APIPaths.resetPassword);

  return callAPI(body, target, {
    status: APIResult.TIME_OUT,
    status_message: "-",
  });
}

export async function passwordLinkValid(datetime, token) {
  var body = {
    datetime: datetime,
    token: token,
  };
  var target = APIPaths.apiEndPoint.concat(APIPaths.passwordLinkValid);

  return callAPI(body, target, {
    status: APIResult.TIME_OUT,
    status_message: "-",
  });
}

export async function getFeed(id) {
  var body = {
    id: id,
  };
  var target = APIPaths.apiEndPoint.concat(APIPaths.getFeed);

  return callAPI(body, target, {
    status: APIResult.TIME_OUT,
    status_message: "-",
  });
}
