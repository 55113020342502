import React from "react";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { getFeed } from "../../utilities/requests";
import APIResult from "../../configs/APIResult";

class FeedPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      content: "",
    };
  }

  componentDidMount() {
    // query string
    const urlParams = new URLSearchParams(window.location.search);
    let id = urlParams.get("id");
    // check if id is number
    if (isNaN(id)) {
      id = 0;
    }
    if (id <= 0) {
      return;
    }
    // get feed content
    this.getFeedData(id);
    this.setState({
      isDark:
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches,
    });
  }

  async getFeedData(id) {
    let getFeedResult = await getFeed(id);
    if (getFeedResult.status === APIResult.SUCCESS) {
      if (getFeedResult.feed.length > 0) {
        this.setState({
          content: getFeedResult.feed[0].feed_content,
        });
        return;
      }
    }
    this.setState({
      content: `# Feed not found 🙁

        It looks like the feed you were looking for is not available.
        
        Please check the URL and try again.
        
        If the problem persists, please contact support for assistance.
        
        Thank you for your patience`,
    });
  }

  render() {
    return (
      <div className={`${!this.state.isDark ? "feed-light" : "feed-dark"}`}>
        <MarkdownPreview source={this.state.content} />
      </div>
    );
  }
}
FeedPage.propTypes = {};

FeedPage.defaultProps = {};

export default FeedPage;
